import React from "react";
import styles from "./index.module.css";

function Body(props) {
  return (
    <div
      className={styles.el}
      dangerouslySetInnerHTML={{
        __html: props.html,
      }}
    />
  );
}

export default Body;
