import GoogleMapReact from "google-map-react";
import React from "react";
import { MdLocationOn } from "react-icons/md";

function Marker(props) {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      <MdLocationOn
        style={{ color: "#ed1a24", height: 50, width: 50 }}
        lat={props.lat}
        lng={props.lng}
      />
      <div
        style={{
          color: "#0a59a3",
          display: "block",
          fontFamily: "Arial",
          fontSize: "14px",
          fontWeight: "600",
          letterSpacing: "1px",
          textTransform: "uppercase",
          whiteSpace: "nowrap",
        }}
      >
        {props.text}
      </div>
    </div>
  );
}

function Map() {
  const defaultProps = {
    center: {
      lat: 39.166372263344904,
      lng: -77.18357914651104,
    },
    zoom: 8.8,
  };
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyACkisUI60NnZtEKv8fsL8olJ2UcBh6rnM" }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
      options={{
        styles: [
          {
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5",
              },
            ],
          },
          {
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161",
              },
            ],
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#f5f5f5",
              },
            ],
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#cedeed",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
              {
                color: "#dadada",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e",
              },
            ],
          },
          {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5",
              },
            ],
          },
          {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#c9c9c9",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e",
              },
            ],
          },
        ],
      }}
    >
      <Marker lat={39.392099} lng={-77.415534} text="Main Office" />
      <Marker lat={39.053711} lng={-76.88767} text="Office/Warehouse" />
      <Marker lat={38.789628} lng={-77.54562} text="Warehouse" />
    </GoogleMapReact>
  );
}

export default Map;
