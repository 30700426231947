import Body from "components/Body";
import Contact from "components/Contact";
import Container from "components/Container";
import Form from "components/Form";
import Heading from "components/Heading";
import Map from "components/Map";
import Section from "components/Section";
import Service from "components/Service";
import Img from "gatsby-image";
import React from "react";
import styles from "./index.module.css";

function Page(props) {
  return (
    <>
      <div className={styles.el}>
        <div className={styles.content}>
          <div>
            {/* Heading */}
            {props.data.heroSectionHeading && (
              <Heading as="h1">{props.data.heroSectionHeading}</Heading>
            )}
          </div>
          <div>
            {/* Body */}
            {props.data.bodySectionContent && (
              <div className={styles.body}>
                <Body
                  html={props.data.bodySectionContent.childMarkdownRemark.html}
                />
              </div>
            )}
            {/* Form */}
            {props.contact && (
              <div className={styles.form}>
                <Form />
              </div>
            )}
            {/* Services */}
            {props.data.serviceComponents &&
              props.data.serviceComponents.map((entry, index) => (
                <Service
                  description={entry.description}
                  key={`service-${index}`}
                  service={entry.service}
                />
              ))}

            {/* Services */}
            {props.data.services && (
              <div className={`${styles.stat} ${styles.services}`}>
                <div className={styles.statLabel}>Services</div>
                {props.data.services.map((service, i) => (
                  <div className={styles.servicesItem} key={i}>
                    {service}
                  </div>
                ))}
              </div>
            )}
            {/* Location */}
            {props.data.location && (
              <div className={styles.stat}>
                <div className={styles.statLabel}>Location</div>
                <div className={styles.statValue}>{props.data.location}</div>
              </div>
            )}
            {/* Value */}
            {props.data.value && (
              <div className={styles.stat}>
                <div className={styles.statLabel}>Project Cost</div>
                <div className={styles.statValue}>{props.data.value}</div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.image}>
          {/* Hero Image */}
          {props.data.heroSectionImage && (
            <Img
              aspectRatio={props.data.heroSectionImage.fluid.aspectRatio}
              fluid={props.data.heroSectionImage.fluid}
              imgStyle={{ objectFit: "cover" }}
            />
          )}
          {/* Map */}
          {props.contact && <Map />}
        </div>
      </div>
      {/* Images */}
      {props.data.images && (
        <Section>
          <Container>
            <div className={styles.images}>
              {props.data.images.map((image, i) => (
                <div className={styles.imagesItem} key={`image-${i}`}>
                  <Img
                    aspectRatio={image.fluid.aspectRatio}
                    className={styles.imagesItemImg}
                    fluid={image.fluid}
                  />
                </div>
              ))}
            </div>
          </Container>
        </Section>
      )}
      {/* Contact */}
      {!props.contact && <Contact />}
    </>
  );
}

export default Page;
